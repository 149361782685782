<template>
  <div class="my-2">
    <v-card>
      <v-form ref="form" v-model="formSettings.formValidity" @submit.prevent="update">
        <v-card-text>
          <v-row>
            <v-col>
              <span class="text-caption">Тема интерфейса</span><br/>
              <v-btn-toggle
                v-model="formSettings.data.AdminDarkMode"
                mandatory
                class="flex-column flex-sm-row"
                @change="changeTheme"
              >
                <v-btn value="system">
                  <v-icon class="mr-2">mdi-brightness-auto</v-icon>
                  Как в системе
                </v-btn>
                <v-btn value="light">
                  <v-icon class="mr-2">mdi-white-balance-sunny</v-icon>
                  Светлый
                </v-btn>
                <v-btn value="dark">
                  <v-icon class="mr-2">mdi-moon-waxing-crescent</v-icon>
                  Темный
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions class="mt-2 no-gutters">
          <v-btn class="ml-1" color="primary" :disabled="!formSettings.formValidity" type="submit">Сохранить</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-overlay :value="busy">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import ToastService from '@/service/ToastService'
import { mapActions, mapGetters } from 'vuex'
import vuetify from '@/plugins/vuetify'

export default {
  ...dashboardPageCommonAttributes,
  name: 'ProfileSettingsPage',
  data: () => ({
    busy: false,
    formSettings: {
      formValidity: false,
      data: {
        AdminDarkMode: 'system'
      }
    }
  }),
  computed: {
    ...mapGetters('profile', ['settingsValue'])
  },
  created: function () {
    this.formSettings.data.AdminDarkMode = this.settingsValue('AdminDarkMode') ?? 'system'
  },
  methods: {
    ...mapActions('profile', ['updateSettings']),
    changeTheme() {
      let isDark = false

      if (this.formSettings.data.AdminDarkMode === 'system') {
        isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      } else {
        isDark = this.formSettings.data.AdminDarkMode === 'dark'
      }
      this.$vuetify.theme.dark = isDark
    },
    update() {
      this.busy = true
      this.updateSettings({ settings: this.formSettings.data })
        .then(() => {
          ToastService.success('Данные сохранены')
        })
        .catch((error) => {
          console.info(error)
          ToastService.error('Что-то пошло не так')
        })
        .finally(() => {
          this.busy = false
        })
    }
  }
}
</script>

<style scoped>

</style>
